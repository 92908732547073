import React from 'react';
import { FieldHookConfig, useField } from 'formik';
import Warning from '../../assets/icons/Warning';
import clsx from 'clsx';

type InputProps = FieldHookConfig<string> & {
  label: string;
};
export const Input: React.FC<InputProps> = ({ label, className, ...rest }) => {
  const [field, meta] = useField(rest);
  const { disabled, placeholder } = rest;

  const hasError = meta.touched && meta.error;

  return (
    <div
      className={clsx(
        'inline-block min-w-48 relative group',
        {
          'pointer-events-none': disabled,
          'text-gray-500': disabled,
        },
        className,
      )}
    >
      <div className="inline-flex flex-col w-full">
        <label className="font-semibold pb-2">{label}</label>
        <div
          className={clsx(
            'font-normal  overflow-hidden rounded-lg border-solid border inline-flex items-center justify-between',
            {
              'bg-gray-500 text-gray-500': disabled,
              'text-black': !disabled,
              'group-hover:border-red-500  border-red-500': hasError,
              'group-hover:border-blue-500 border-gray-300': !hasError,
            },
          )}
        >
          <input
            className={clsx('py-3 px-4 flex-1 outline-none placeholder-gray-500', {
              'bg-gray-100': disabled,
              'text-gray-500': disabled,
            })}
            disabled={disabled}
            placeholder={placeholder}
            {...field}
          />
          {hasError ? <Warning className="w-4 h-4 mr-4 text-red-500" /> : null}
        </div>
      </div>
      {hasError ? <div className="font-normal text-sm text-red-500 mt-2">{meta.error}</div> : null}
    </div>
  );
};
