import React, { useRef, useState } from 'react';
import { FieldProps } from 'formik';
import * as Yup from 'yup';
import { Camera } from '../../assets/icons';
import Warning from '../../assets/icons/Warning';
import { TFunction } from 'i18next';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

type UploadImageInputProps = FieldProps<File> & {
  label: string;
  initialImageUrl: string;
  isBottom?: boolean;
};

const FILES_FORMAT_ACCEPTED = ['image/jpg', 'image/jpeg', 'image/png'];
const FILE_SIZE = 4;
export const imageValidationSchema = (t: TFunction) =>
  Yup.mixed()
    .test('fileSize', t('settings:fileTooLarge'), (value) => (value ? value.size / 1024 / 1024 <= FILE_SIZE : true))
    .test('fileFormat', t('settings:imageBadFormat'), (value) =>
      value ? FILES_FORMAT_ACCEPTED.includes(value.type) : true,
    );

export const UploadImageInput: React.FC<UploadImageInputProps> = ({
  label,
  initialImageUrl,
  isBottom = false,
  form: { setFieldValue, ...form },
  field,
}) => {
  const inputFileRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation(['settings']);
  const [imageUrl, setImageUrl] = useState(initialImageUrl);
  const [, setImageFile] = useState<File | undefined>(undefined);

  const errorMessage = form.errors['image'];

  const handleInputClick = () => {
    if (inputFileRef.current) {
      inputFileRef.current.click();
    }
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    let reader = new FileReader();
    if (!e.target.files) {
      return;
    }
    let file = e.target.files[0];
    if (file) {
      reader.onloadend = () => {
        setImageFile(file);
        setImageUrl(reader.result as string);
      };
      reader.readAsDataURL(file);
      setFieldValue(field.name, file);
    }
  };

  const classNames = clsx('flex items-center', {
    'flex-col space-y-6': isBottom,
    'space-x-6': !isBottom,
  });

  return (
    <div className={classNames}>
      <div className="relative cursor-pointer w-24 h-24" onClick={handleInputClick}>
        <img src={imageUrl} alt="preview" className="w-24 h-24 object-cover rounded-full" />
        <div className="absolute w-10 h-10 rounded-full bg-gray-100 flex items-center justify-center bottom-0 right-0">
          <Camera />
        </div>
      </div>
      <input
        type="file"
        multiple={false}
        className="hidden"
        ref={inputFileRef}
        onChange={handleImageChange}
        accept={FILES_FORMAT_ACCEPTED.toString()}
      />
      {errorMessage ? (
        <div className="font-normal text-sm text-red-500 flex items-center mt-2">
          <Warning className="w-4 h-4 mr-1 text-red-500" />
          {errorMessage}
        </div>
      ) : null}
      <div className="bg-blue-100 p-3 rounded-lg text-sm flex">
        <div className="mr-3">
          <Warning className="w-4 h-4 text-blue-500 transform rotate-180" />
        </div>
        <div>
          <div>
            <span className="font-semibold">{t('settings:preferASquaredImage')}</span>
          </div>
          <div>
            <span className="font-semibold">{t('settings:formats')} :</span> JPG, JPEG, PNG
          </div>
          <div>
            <span className="font-semibold">{t('settings:maxSize')} :</span> 4 MB
          </div>
        </div>
      </div>
    </div>
  );
};
